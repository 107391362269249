:root {
    --main-001: #303135;
    --main-002: #26272bee;
    --main-003: #1f2023;
    --main-004: #111213;

    --seco-001: #ec4619;
    --seco-002: #ec3205;
    --seco-003: #d81e05;
    --seco-004: #c40a05;

    --thir-001: #edf1f8;
    --thir-002: #d9dde4;
    --thir-003: #c5c9d0;
    --thir-004: #b1b5bc;

    --four-001: #16feea;

    --shade-001: 0 0 2px #1117, 0 0 4px #1116, 0 0 8px #1115, 0 0 12px #1114;
    --shade-002: inset 0 0 2px #1117, inset 0 0 4px #1116, inset 0 0 8px #1115,
        inset 0 0 12px #1114;
    --shade-003: 0 1px 2px #1117, 0 2px 4px #1116, 0 3px 8px #1115,
        0 5px 12px #1114;
    --shade-004: 0 0 2px #1115, 0 0 4px #1114, 0 0 8px #1113, 0 0 12px #1112;
}

body {
    margin: 0;
    overflow: hidden;
    overflow-y: auto;
    min-height: 100vh;
    min-width: 100vw;
    max-height: 100vh;
    max-width: 100vw;
    background-color: var(--main-004);
    color: var(--thir-001);
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    display: flex;
    flex-flow: column nowrap;
}
* {
    font-family: "Ubuntu", sans-serif;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
::placeholder {
    color: var(--main-003);
}
::-webkit-scrollbar {
    max-width: 0;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
a {
    text-decoration: none;
    color: var(--thir-001);
}
